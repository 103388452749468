<template>
  <div class="input-group">
    <label
      v-if="!noLabel"
      :style="{ width: labelWidth + 'px', 'text-align': Align }"
      class="control-label"
      ><i v-if="isMustFill" class="fa fa-asterisk"></i>{{ title || "港口" }}</label
    >
    <!-- <div>
            <select class="form-control" v-model="value" @change="selectChange" :style="{width:inputWidth + 'px'}" :disabled="isDisabled" @focus="focusPort" :placeholder="placeholder">
                <option v-for="item in viewData" :value="item.portId">{{item.portName}}</option>
            </select>
          </div> -->
    <el-select
      v-model="value"
      filterable
      :multiple="multiple"
      @change="selectChange"
      clearable
      :style="{ width: inputWidth + 'px' }"
      :disabled="isDisabled"
      :placeholder="placeholder"
      size="mini"
    >
      <el-option
        v-for="(item, index) in viewData"
        :key="item.portId"
        :label="item.portName"
        :value="item.portId"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: "dk-select-port",
  data() {
    return {
      viewData: [],
    };
  },
  created() {
    // this.getUserPort()
  },
  mounted() {
    this.getPorts();
  },
  props: {
    noLabel: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    needShowAll: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: Number,
      default: 85,
    },
    isMustFill: {
      type: Boolean,
      default: false,
    },
    needEmpty: {
      type: Boolean,
      default: true,
    },
    Align: {
      type: String,
      default: "center",
    },
    inputWidth: {
      type: Number,
      default: 150,
    },
    Align: {
      type: String,
      default: "center",
    },
    placeholder: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getPorts: function () {
      var that = this;
      var arr = [];
      _.util.ajax_get("/common/findPort/list", {}, (res) => {
        console.log("findPort/list-->res:", res);
        if (that.needShowAll) {
          arr.push({
            portId: "",
            portName: "全部",
          });
        } else if (that.needEmpty) {
          arr.push({
            portId: "",
            portName: "",
          });
        }
        var list = JSON.parse(res.data.value);
        list.forEach((v) => {
          if (v.portId) {
            arr.push({
              portId: v.portId,
              portName: v.portName,
            });
          }
        });
        that.viewData = arr;
        that.$emit("list", arr);
      });
    },
    selectChange: function () {
      let portName = "";
      this.viewData.forEach((v) => {
        if (v.portId == this.value) {
          portName = v.portName;
        }
      });
      this.$emit("input", this.value);
      this.$emit("change", this.value);
      this.$emit("change1", portName);
    },
    focusPort() {
      this.$emit("focusport");
    },
  },
};
</script>
